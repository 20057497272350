//https://www.figma.com/file/o2EAg5GDnZ2gVMHCuRrwPJ/ESO-Design-System?node-id=919%3A1949
$brand: #2e3f50;
$primary-red: #d41616;
$accent-red: #ea3535;
$primary-blue: #3665fc;
$accent-blue: #4285f4;
$primary-green: #008837;
$accent-green: #2ecc71;
$primary-white: #ffffff;
$primary-gray: #6b7680;
$accent-gray-dark: #969fa7;
$accent-gray-mid: #dee0e2;
$accent-gray-light: #f8f8f9;
$accent-orange: #e17a00;
$accent-yellow: #FBBC05;

$sm: '576px';
$md: '768px';
$lg: '992px';
$xl: '1200px';

:root {
  //primeng theme overrides
  --primary-color: $brand;
  --secondary-color: $accent-green;
  --success-color: $primary-green;
  --danger-color: $primary-red;
  --warning-color: $accent-orange;
}
